<template>
   <p>
    <span v-if="log.creationDate" class="time mr-1">{{ formatDate(log.creationDate, { hour: 'numeric', minute: 'numeric' }) }}</span>
    <span v-if="log.updateDate" class="time mr-1">{{ formatDate(log.updateDate, { hour: 'numeric', minute: 'numeric' }) }}</span>
    <small class="notification-text">{{ log.type.replace('_',' ') }}</small>
    <br/>
    <small class="notification-text">Ονομασία προϊόντος: {{ log.productName }}</small>
    <br/>
    <small class="notification-text">Χρήστης: {{ log.userName }}</small>
  </p>
</template>
<script>
import { formatDate } from '@core/utils/filter'

export default {
  props: {
    log: {
      type: Object,
      required: true,
    },
  },
  setup() {
    return {
      formatDate,
    }
  },
}
</script>